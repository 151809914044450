import {
    ItemBag,
    ItemBox,
    ItemBoxes,
    ItemCalendar,
    ItemGift,
    ItemBlocks,
    ItemMoney,
    ItemStopwatch,
} from './assets/images';

import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
} from './utils/sectionRefs';

export const RATY0_HERO_CONFIG = {
    title: "Złóż zamówienie dzisiaj i płać za 30 dni lub jeszcze później",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    content: [
        {
            text: "Zamawiasz jak zwykle w sklepach, które oferują usługę Zapłać w ratach z możliwością spłaty w ratach 0% (RRSO 0%). Odbierasz zakupy, sprawdzasz produkty i decydujesz, czy chcesz je zatrzymać. Spłacasz do 30 dni lub w miesięcznych ratach 0% - czyli bez dodatkowych kosztów.",
        },
    ],
    heroGraphic: "raty0",
};

export const RATY0_ORDERED_ITEMS_CONFIG = {
    title: "Jak to działa?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Krok 1",
            text: 'Składając zamówienie, wybierasz usługę Zapłać w ratach".',
        },
        {
            id: 2,
            title: "Krok 2",
            text: "PayPo płaci za Ciebie rachunek.",
        },
        {
            id: 3,
            title: "Krok 3",
            text: "Otrzymujesz swoje zakupy.",
        },
        {
            id: 4,
            title: "Krok 4",
            text: "W ramach usługi Zapłać w ratach z ratami 0% możesz opłacić swoje zamówienie za 30 dni lub skorzystać z nieoprocentowanych rat. Bez dodatkowych kosztów.",
        },
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        },
        {
            bottom: "6rem",
            left: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "81.39deg",
        },
        {
            bottom: "-4rem",
            right: "-15.5rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const RATY0_ICON_ITEMS_CONFIG = {
    title: "Korzyści",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: ItemCalendar,
            description: "<b>Widząc świetną okazję</b>, która kończy się niebawem, nie musisz od razu decydować, czy skorzystasz - płacąc z PayPo, dajesz sobie czas na podjęcie decyzji.",
        },
        {
            Icon: ItemBlocks,
            description: "<b>Nic Cię to nie kosztuje</b>. Nie pobieramy dodatkowych opłat. Możesz pozwolić sobie na więcej - zaszaleć na wyprzedażach lub kupić prezent sobie czy bliskim, a spłacając zamówienie, skorzystać z rat. ",
        },
        {
            Icon: ItemGift,
            description: "<b>Korzystasz z atrakcyjnych promocji</b> i dedykowanych kodów rabatowych od naszych Partnerów",
        },
        {
            Icon: ItemBag,
            description: "<b>Robisz pewne zakupy</b> - płacisz, dopiero po odebraniu i sprawdzeniu zamówienia, bez mrożenia swoich własnych środków pieniężnych.",
        },
    ],
    additionalText: [
        {
            text: 'Przykład reprezentatywny: Rzeczywista Roczna Stopa Oprocentowania (RRSO) wynosi 0,00%, całkowita kwota kredytu (bez kredytowanych kosztów) 240,00 zł, całkowita kwota do zapłaty 240,00 zł, oprocentowanie 0%, całkowity koszt kredytu 0 zł (w tym prowizja 0 zł, odsetki 0 zł), 4 raty równe po 60 zł, okres obowiązywania umowy - 120 dni. Kalkulacja została dokonana na dzień 15.12.2022 r. na reprezentatywnym przykładzie. Udzielenie kredytu jest uzależnione od oceny zdolności kredytowej klienta przez PayPo Sp. z o.o. Kredyt konsumencki wiązany z prowizją i oprocentowaniem 0% dostępny jest w wybranych sklepach. Warunki udzielania kredytu zawarte są w Regulaminie usługi “Zapłać w ratach” dostępnym na <a href=\'https://www.paypo.pl\'>www.paypo.pl</a>. Okres obowiązywania umowy wynosi 120 dni.'
        },
    ],
    squares: [
        {
            bottom: "-14rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "-16rem",
            right: "-15.5rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "7rem",
            right: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
    ],
};

export const RATY0_ACCORDION_SECTION = {
    title: "Najczęściej zadawane pytania",
    id: "section-faq",
    forwardedRef: faqRef.forwardedRef,
    navigationRef: faqRef.navigationRef,
    content: [
        {
            id: "1",
            title: "1. Czy muszę podawać dane swojej karty kredytowej/debetowej?",
            text: "Nie, do skorzystania z płatności PayPo nie wymagamy podania danych karty kredytowej/debetowej. ",
            isExpanded: true,
        },
        {
            id: "2",
            title: "2. W jaki sposób płacę za zamówienie?",
            text: "Możesz zapłacić przelewem bankowym, przekazem pocztowym lub szybkimi płatnościami online - jak Ci wygodniej. Numer konta do wpłaty otrzymasz mailem wraz z potwierdzeniem złożenia zamówienia. Dla Twojej wygody udostępniamy panel Klienta - nie masz obowiązku logować się do niego, ale możesz. Tam masz bieżący podgląd wszystkich ważnych informacji.",
        },
        {
            id: "3",
            title: "3. Czy to jest bezpieczne?",
            text: "Tak, wszystkie płatności są realizowane w ramach szyfrowanego połączenia, a Ty nie podajesz żadnych danych dostępowych do swojego konta.",
        },
        {
            id: "4",
            title: "4. Czy to coś kosztuje?",
            text: "Nie. Korzystając z usługi “Zapłać w ratach” z ratami 0%, spłacasz tylko tyle, ile wynosi wartość zamówienia - bez dodatkowych kosztów.",
        },
        {
            id: "5",
            title: "5. Co, jeśli nie zapłacę w ciągu 30 dni?",
            text: "Nie martw się, nic się nie stanie. Po 30 dniach możesz skorzystać z płatności na raty 0%",
        },
        {
            id: "6",
            title: "6. Co się stanie, jeśli zwrócę zamówienie?",
            text: "Po prostu nie zapłacisz. Twoja transakcja zostanie anulowana w naszym systemie.",
        },
    ],
    squares: [
        {
            top: "54rem",
            left: "13rem",
            size: "6rem",
            bgColor: colors.fuchsia500,
            rotate: "78.54deg",
        },
        {
            top: "6rem",
            right: "10rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "-6rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};
