import React from 'react';

import { colors } from '../../styles/variables';

import CookieModal from '../../components/organisms/CookieModal';
import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import Navbar from '../../components/organisms/Navbar';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    START_NAVBAR_CONFIG,
    START_INLINE_TILES_CONFIG,
    START_BIG_TILES_CONFIG,
} from '../../content_config_start';

import {
    RATY0_HERO_CONFIG,
    RATY0_ORDERED_ITEMS_CONFIG,
    RATY0_ICON_ITEMS_CONFIG,
    RATY0_ACCORDION_SECTION,
} from '../../content_config_raty0';

const IndexRaty0 = () => (
    <div className="page-body sticky-navbar">
        <SectionAppearanceProvider>
            <Seo title="Start PayPo.pl" />

            <Navbar
                config={ START_NAVBAR_CONFIG }
                sticky
            />

            <SectionHeroStart
                config={ RATY0_HERO_CONFIG }
            />

            <SectionOrderedItems
                config={ RATY0_ORDERED_ITEMS_CONFIG }
                isColorSectionBelow={ true }
            />

            <SectionIconItems
                config={ RATY0_ICON_ITEMS_CONFIG }
                background={ colors.grayCultured }
                columns={ 2 }
            />

            <SectionAccordion
                config={ RATY0_ACCORDION_SECTION }
                isColorSectionAbove={ true }
            />

            <SectionInlineTiles
                config={ START_INLINE_TILES_CONFIG }
            />

            <SectionBigTiles
                config={ START_BIG_TILES_CONFIG }
            />

            <CookieModal />
        </SectionAppearanceProvider>
    </div>
);

export default IndexRaty0;
